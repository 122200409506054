<template>
    <div class="home">
    	<Header/>
<Footer/>
        <div class="container">
            <br>
            <div class="logo">
                <h1>K</h1>
            </div>
            <h1 class="name">Kundan K Singh</h1>
        </div>
    </div>
</template>

<script>
import Header from "./../components/Header.vue";
import Footer from "./../components/Footer.vue";
export default {
    name: "Home",
    components: {
        Header,
        Footer,
    }
};
</script>